<template>
  <div class="input-group">
    <b-form-input
      v-model="search"
      @keyup="debounceInput"
      @keyup.esc="clean"
      :placeholder="searchText"
    ></b-form-input>
    <font-awesome-icon icon="search" class="icon-search" v-if="showSearch" />
    <svg
      class="icon-balance"
      v-if="showFiltre && !search"
      v-b-modal.entrepriseModal
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 27 18"
    >
      <path
        id="Icon_material-filter-list"
        data-name="Icon material-filter-list"
        d="M15,27h6V24H15ZM4.5,9v3h27V9ZM9,19.5H27v-3H9Z"
        transform="translate(-4.5 -9)"
        fill="#28367a"
      />
    </svg>
    <div class="icon-clean" v-show="search" @click="clean">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.028"
        height="17.028"
        viewBox="0 0 17.028 17.028"
      >
        <path
          id="Icon_material-close"
          data-name="Icon material-close"
          d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
          transform="translate(-7.5 -7.5)"
          fill="#e4261b"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      search: "",
      currententreprise: null,
    };
  },

  props: {
    searchFunc: Function,
    showFiltre: Boolean,
    filtre: { type: Object, default: () => {} },
    searchText: { type: String, default: `Chercher` },
    showSearch: { type: Boolean, default: true },
  },

  methods: {
    ...mapActions([]),

    debounceInput: _.debounce(function () {
      this.$emit("returnValue", this.search);
      var payload = {
        page: 1,
        per_page: 10,
        ...this.filtre,
      };
      if (this.search) payload.search = this.search;

      this.searchFunc(payload);
    }, 500),

    clean() {
      this.search = "";
      this.debounceInput();
    },
  },

  computed: {
    ...mapGetters([]),
  },
};
</script>

<style lang="scss" scoped>
.input-group .icon-balance {
  top: 8px;
}

.input-group .icon-clean {
  cursor: pointer;
  overflow: visible;
  position: absolute;
  right: 15px;
  top: 4px;
  outline: 0;
  z-index: 5;
}
</style>
